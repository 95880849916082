/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "He visto ultimamente dos peliculas muy buenas, las dos son claras criticas al capitalismo, en las dos sus protagonistas se enfrentan al sistema establecido, luchando a contracorriente por salvar el cuello, estoy ablando de El club de la lucha y de El concursante, las diferencias entre las dos es que la primera no se puede quitar ese sentimiento de grandeza tipico del cine de hollywood y la segunda es mas trajica, no os digo mas que la pelicula empieza con la muerte del protagonista y este contando su historia."), "\n", React.createElement(_components.p, null, "Estas dos peliculas puede que sean una buena introduccion al siguiente documental,zeitgeist. Este es un documental muy largo y dividido en tres partes; la primera parte trata sobre religiones y mito, la segunda sobre el 11-s ( es curioso que en EEUU este dia lo escriban como 911, el telefono la policia) y como lo aprovecharon como excusa para comenzar guerras, y la ultima parte la parte de la gran conspiración. Aunque puede que muchas cosas de este video no sean verdad la cantidad de pruebas que aportan es sorprendente y ademas animan a quien lo vea descubrir por el mismo, en su pagina web se pueden comprobar sus fuentes. Bueno nada mas os dejo con el video espero que os guste ", React.createElement(_components.a, {
    href: "http://es.youtube.com/watch?v=Bo7WhuSs7FQ"
  }, "es solo la primera parte hay 12.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
